import React, { useState, useEffect } from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import * as styles from './about.module.scss'

const AboutPage = ({pageContext}) => {
  const {text, image} = pageContext.content[0].node
  return (
    <Layout pageclass='about'>
      <Seo title="About" />
      <div className={`${styles.root} grid-parent`}>
        <div className={styles.imageWrapper}>
          <div className={styles.image} style={{backgroundImage: `url(${image.file.url})`}}></div>
        </div>
        <div className={styles.text} dangerouslySetInnerHTML={{ __html: text.childMarkdownRemark.html }}></div>
      </div>

      <div className={styles.credit}>
        Site is designed by <a href="https://shukangyu.com/" target='_blank'>808:Studio</a> and developed by <a href="https://yanlinma.com/" target='_blank'>Yanlin Ma</a>
      </div>
    </Layout>
  )
}

export default AboutPage